import {init, TrackersNames, getTracker, trackEvent } from "@workiz/workiz-analytics-tracker";
import config from '../config';
import store from '../store';


init({
  [TrackersNames.amplitude]: {
    apiKey: config.AMPLITUDE_API_KEY,
  }
})

function getPaymentSolution(gateway) {
  switch(gateway){
    case 'workiz-stripe':
      return 'stripe';
    case 'workiz':
      return 'wepay';
    default:
      return 'null';
  }
}

export function setExtraProperties() {
  const storeData = store.getState();
  getTracker(TrackersNames.amplitude).groupIdentify(storeData?.account?.accountId, {
    account_id: storeData?.account?.accountId,
    paymentSolution: getPaymentSolution(storeData?.account?.paymentSettings?.gateway),
    env: 'BookingV2'
  })
}

export function amplitudeEvent(eventName, props = {}) {
  trackEvent(TrackersNames.amplitude, eventName, props)
}
