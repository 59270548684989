import React from 'react';
import './ServiceDetailsModal.scss';
import '../Services/Service/Service.scss';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import closeImg from '@assets/images/close.svg';
import { useDispatch} from "react-redux";
import {updateServiceQuantity, updateServicesTotalAmount} from '@actions/servicesActions';
import Tooltip from 'react-tooltip-lite';
import minusIcon from '@assets/images/minusIcon.svg';
import plusIcon from '@assets/images/plusIcon.svg';
import minusNoQuantityIcon from '@assets/images/minusNoQuantityIcon.svg';
import {formatCurrency} from "../../helpers/numbers";

const ServiceDetailsModal = ({isOpen, closeAction, title, serviceInfo, accountTaxRate}) => {
    const dispatch = useDispatch();

    const DetailsContent = () => {
        if(!serviceInfo) return null;
        return <div className="detailsModal">
            {serviceInfo.img_url && <div className="image">
                <img className='img' alt='service' src={serviceInfo.img_url} />
            </div>}
            <div className="wrapper-details">
                <div>
                    <Tooltip
                        className='title-tooltip'
                        tipContentClassName='title-tooltip-content'
                        tagName='span'
                        content={serviceInfo.item_name}
                        arrowSize={4}
                        zIndex='1400'
                        background= '#6AA8EE'
                        distance={50}
                    >
                        <h3 className="title">{serviceInfo.item_name}</h3>
                    </Tooltip>
                </div>
                <div className={`price ${serviceInfo.quantity ? 'withQuantity' : ''} ${serviceInfo.img_url ? 'priceModal' : ''}`}>{formatCurrency(serviceInfo.booking_price)}</div>
                {renderAddToCart()}
                <div className={`description ${serviceInfo.img_url ? '' : 'noImg'}`}>{serviceInfo.long_desc}</div>
            </div>
        </div>;
    }

    const quantityChange = (action) => {
        dispatch(updateServiceQuantity({serviceId: serviceInfo.p_id, action}));
        dispatch(updateServicesTotalAmount({
                price: serviceInfo.booking_price,
                taxable: serviceInfo.taxable,
                taxRate: accountTaxRate,
                action
            }));
    }

    const renderAddToCart = () => {
        return <div className = "info-wrap">
                    <div className="quantity-wrapper">
                    <div onClick={() => serviceInfo.quantity === 0 ? false : quantityChange('remove') }
                    className={`quantity-circle remove ${!serviceInfo.quantity ? "noQuantityBtn" : ""}`}><img src={serviceInfo.quantity ? minusIcon : minusNoQuantityIcon} alt='icon' />
                    </div>
                    <div className={`quantity ${!serviceInfo.quantity ? "no-quantity" : ""}`}>{ serviceInfo.quantity }</div>
                    <div onClick={() => { quantityChange('add') }}
                        className="quantity-circle add"><img src={plusIcon} alt='icon'/>
                    </div>
            </div>
        </div>
    }

    return (
        <Dialog
            open={isOpen}
            onClose={closeAction}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            id="dialog"
            PaperProps={{
                style: {
                  borderRadius: '16px',
                  margin: '0px',
                  maxWidth: 'calc(100% - 30px)',
                  overflow: 'unset'
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">
            <span className='closeIcon' onClick={closeAction}>
                <img src={closeImg}/>
            </span>
                {title}
            </DialogTitle>
            <DialogContent id='dialog-content'>
                    {DetailsContent()}
            </DialogContent>
        </Dialog>
    )
}

export default ServiceDetailsModal;
